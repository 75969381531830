import React, { Component } from "react";
import { toast } from "react-toastify";
// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";

import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, FormControl, TextField } from "@mui/material";

import logo from "../../images/logo.png";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";

//API handling components
import { API_URL } from "../../Global";
//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";


import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";


// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export class NonQuotetionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showViewModal: false,
            showPriceUpdateModal: false,
            currentBillId: null,
            billList: [],
            itemsList: null,
            updatedPrice: 0,
            startingDate: Date.now(),
            endingDate: Date.now()
        };
    }

    fetchBillList = () => {
        const startingDate = new Date(this.state.startingDate)
        const startingFilterDate = startingDate.toISOString()
        const endDate = new Date(this.state.endingDate)
        const endFilterDate = endDate.toISOString()
        let url = API_URL;
        const query = `SELECT * FROM nonGstBill as q where q.status=1 AND DATE(last_modified) BETWEEN '${startingFilterDate}' AND '${endFilterDate}}'
        ORDER BY q.id DESC;`;
        // const query = `SELECT * FROM gstBill  where  status=1  and date between "${this.state.startingDate}" and "${this.state.endingDate} "ORDER BY id DESC ;`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill: ", res.data);
                this.setState({ billList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("Bill list fetch error: ", err);
            });
    };

    fetchBillItemList = () => {
        let url = API_URL;
        const query = `SELECT * FROM billList where billId = '${this.state.currentBillId}' and billType = 2;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill list data: ", res.data);
                this.setState({ itemsList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("bill list fetch error: ", err);
            });
    };

    deleteRecord(id) {
        let url = API_URL;
        const query = `UPDATE nonGstBill SET status = 0  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                toast.success("Record deleted successfully");
                this.fetchBillList();
                this.refresh();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    }
    submitUpdatedPrice = (e) => {
        let url = API_URL;

        const query = `UPDATE nonGstBill SET paid = paid + ${this.state.updatedPrice}, balance = balance-${this.state.updatedPrice}  WHERE id=${this.state.currentBillId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("updated status data: ", res.data);
                toast.success("Record updated successfully");
                this.fetchBillList();
                this.refresh();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
                toast.error("Failed to delete record");
            });
    };

    initializeDataTable() {
        $(function () {
            $("#billHistoryTable")
                .DataTable({
                    destroy: true,
                    dom:
                        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                        "<'row'<'col-sm-12' tr>>" +
                        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                    responsive: true,
                    lengthChange: true,
                    autoWidth: false,
                    buttons: ["csv", "print"],
                    order: [[0, "desc"]],
                })
                .buttons()
                .container();
        });
    }

    refresh() {
        window.location.reload(false);
    }

    componentDidMount() {
        this.fetchBillList();
    }

    renderBillList = () => {
        if (this.state.billList.length === 0) return null;
        // else
        return this.state.billList.map((bill) => {
            return (
                <tr align="center" key={bill.billNumber}>
                    <td>{bill.id}</td>
                    <td>
                        {
                            ["imprint 24", "signmart", "imprint24/signmart"][
                            bill.companyType - 1
                            ]
                        }
                    </td>
                    <td>{bill.partyName}</td>
                    <td>{bill.address}</td>
                    <td>{bill.mobileNo}</td>
                    <td>{bill.total}</td>
                    <td>{bill.paid}</td>
                    <td>{bill.balance}</td>
                    <td>{moment(bill.date).format("DD/MM/YYYY")}</td>
                    <td className="d-flex justify-content-center">
                        &nbsp;
                        <PreviewIcon
                            className="CPointer"
                            onClick={() => {
                                this.setState(
                                    {
                                        showViewModal: true,
                                        currentBillId: bill.id,
                                        billNumber: bill.billNumber,
                                        name: bill.partyName,
                                        address: bill.address,
                                        mobileNo: bill.mobileNo,
                                        total: bill.total,
                                        paymentMode: bill.paymentMode,
                                        date: bill.date,
                                    },
                                    this.fetchBillItemList
                                );
                            }}
                            color="success"
                        />
                        <EditIcon
                            className="ml-2 border border-primary"
                            onClick={() => {
                                this.setState({
                                    currentBillId: bill.id,
                                    showPriceUpdateModal: true,
                                });
                            }}
                            color="primary"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <DeleteIcon
                            className="CPointer"
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(bill.id);
                                }
                            }}
                            color="error"
                        />
                    </td>
                </tr>
            );
        });
    };

    renderViewBill = () => {
        return (
            <Modal
                onHide={(e) => this.setState({ showViewModal: false })}
                size="lg"
                show={this.state.showViewModal}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bill Details</Modal.Title>
                </Modal.Header>
                <Row>
                    {/* Overview */}

                    <div
                        className="ReceptContainerss"
                        //   ref={(el) => setPrintComponentRef(el)}
                        ref={(el) => (this.printComponentRef = el)}
                    >
                        <div className="text-center">
                            <div className="header  ReceptBg ">
                                <div className="row logoContainer">
                                    <div className="col-6 text-start">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="img-fluid"
                                        />
                                    </div>

                                    <div className="col-6">
                                        <p className="py-3 pl-4 text-start">
                                            Shop No.2, vithoba Complex,
                                            Sangli-Miraj Road <br />{" "}
                                            Nr.Parshwanthnagar Bus Stop, Miraj -
                                            416 410 (Maha) <br /> Mobile:
                                            7293242424, e-mail:
                                            imprint24.india@gmail.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h5 className="bg-secondary text-white px-2">
                                INVOICE
                            </h5>
                            <div className="p-2 text-start">
                                <div className="row">
                                    <div className="col-6">
                                        <p className="text-start">
                                            Party Name: &nbsp; {this.state.name}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            {" "}
                                            Phone No: &nbsp;
                                            {this.state.mobileNo}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>
                                            Address: &nbsp; {this.state.address}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            Date :
                                            <b>
                                                {moment(this.state.date).format(
                                                    "D/M/YYYY"
                                                )}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>
                                            Bill Number: &nbsp;{" "}
                                            {this.state.billNumber}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            Payment Mode: &nbsp;{" "}
                                            {
                                                ["Cash", "Online"][
                                                this.state.paymentMode - 1
                                                ]
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h6 className="bg-secondary text-secondary px-2">
                                .
                            </h6>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Sq. ft.</th>
                                        <th>Unit</th>
                                        <th>Rate / sq.ft.</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.itemsList &&
                                        this.state.itemsList.map((item, id) => {
                                            return (
                                                <tr key={id}>
                                                    <td>{id + 1}</td>
                                                    <td>{item.particular}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>
                                                        {" "}
                                                        {item.width +
                                                            " X " +
                                                            item.height}
                                                    </td>
                                                    <td>Sq. ft.</td>
                                                    <td>{item.rate}</td>
                                                    <td>
                                                        {Number(
                                                            item.amount
                                                        ).toFixed(2)}
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                    <tr>
                                        <td colSpan={6}>
                                            <b>Grand Total</b>
                                        </td>
                                        <td>
                                            <b>
                                                {this.state.total &&
                                                    this.state.total.toFixed(2)}
                                            </b>
                                        </td>
                                    </tr>
                                    <div className="Qrcode">
                                        <img
                                            src="/Assets/QrCode1.jpg"
                                            className="qrimg"
                                            alt="QrCode"
                                        />
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* invoice */}
                    <div className="">
                        <ReactToPrint content={() => this.printComponentRef}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button
                                        onClick={handlePrint}
                                        className="mb-5 mt-3 mr-3"
                                        color="primary"
                                        variant="contained"
                                        style={{
                                            float: "right",
                                            marginRight: "40px",
                                        }}
                                    // disabled={saveFlag == true ? false : true}
                                    // disabled={
                                    //         (this.state.particular ||
                                    //             this.state.quantity)
                                    //             ? false
                                    //             : true
                                    //     }
                                    >
                                        PRINT
                                    </Button>
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                    </div>
                </Row>
            </Modal>
        );
    };

    renderPriceUpdate = () => {
        return (
            <Modal
                onHide={(e) => this.setState({ showPriceUpdateModal: false })}
                size="md"
                show={this.state.showPriceUpdateModal}
                // onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        id="outlined-basic"
                        size="small"
                        label="New Paid Amount"
                        type="number"
                        className="inputField  mx-2"
                        name="advance"
                        variant="outlined"
                        required="true"
                        style={{ width: "200px" }}
                        onChange={(e) =>
                            this.setState({ updatedPrice: e.target.value })
                        }
                    />
                    <Button
                        variant="contained"
                        className="AddButtons"
                        onClick={this.submitUpdatedPrice}
                    >
                        Update
                    </Button>
                </Modal.Body>
            </Modal>
        );
    };
    render() {
        return (
            <div className="my-4">
                {/* date picker */}
                {/* <TextField
                    id="startingDate"
                    // label="startingDate"
                    variant="outlined"
                    format="MM/DD/YYY"
                    className="mr-2"
                    value={this.state.startingDate}
                    onChange={(e) =>
                        this.setState({ startingDate: e.target.value })
                    }
                    // required="true"
                    size="small"
                    type="date"
                    style={{ width: "180px" }}
                /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker format="DD/MM/YYYY" label="From" disableFuture value={dayjs(this.state.startingDate)} onChange={(event) => { this.setState({ startingDate: event.$d }) }} />
                        <DatePicker format="DD/MM/YYYY" label="To" disableFuture value={dayjs(this.state.endingDate)} onChange={(event) => { this.setState({ endingDate: event.$d }) }} />
                    </DemoContainer>
                </LocalizationProvider>
                <Button variant='contained' onClick={this.fetchBillList}>Search</Button>
                {/* {console.log("startingDate" + "" + this.state.startingDate)} */}

                {/* <TextField
                    id="endingDate"
                    // label="endingDate"
                    format="MM/DD/YYY"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.endingDate}
                    onChange={(e) =>
                        this.setState({ endingDate: e.target.value })
                    }
                    // required="true"
                    size="small"
                    type="date"
                    style={{ width: "180px" }}
                /> */}

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker label="From" />
                    </DemoContainer>
                </LocalizationProvider> */}
                {/* {console.log("endingDate" + "" + this.state.endingDate)} */}

                <Button
                    color="primary"
                    variant="contained"
                    className="mt-1 mr-1 mb-3 ml-5"
                // onClick={this.refresh}
                >
                    <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                </Button>
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        {
                            this.state.billList.length > 0 ? <div>
                                <table
                                    id="billHistoryTable"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr align="center">
                                            <th align="center">Bill Ids</th>
                                            <th align="center">Company Type</th>
                                            <th align="center">Name</th>
                                            <th align="center">Address</th>
                                            <th align="center">Mobile No.</th>
                                            <th align="center">Total</th>
                                            <th align="center">Paid</th>
                                            <th align="center">Pending</th>
                                            <th align="center">Date</th>
                                            <th align="center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderBillList()}
                                    </tbody>
                                </table>
                                {this.renderViewBill()}
                                {this.renderPriceUpdate()}
                            </div> :
                                <h5 className="text-center">Data not found</h5>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default NonQuotetionHistory;
