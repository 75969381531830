import { TextField } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "@mui/material/Button";
import { API_URL } from "../Global";
import { FilterContext } from "./../App";
const axios = require("axios");

const InquiryProduct = (props) => {
    const { currentType, currentCompany } = useContext(FilterContext);

    const [currentUnitPrice, setCurrentUnitPrice] = useState(0);

    const [currentProduct, setCurrentProduct] = useState("");
    const [products, setProducts] = useState(null);

    const insertNewProduct = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `insert into inquiryProducts (name, price, customerType, company) values ('${currentProduct}', ${currentUnitPrice}, 2, ${currentCompany})`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("insert successful");
                toast.success("Product added successfully");
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            })
            .catch((err) => {
                console.log("products insert error: ", err);
            });
    };
    const fetchProductList = () => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `select id, name as label, price from inquiryProducts where company=${currentCompany} and status = 1 `;

        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    


    useEffect(() => {
        fetchProductList();
    }, [currentCompany]);

    const deleteProduct = (id) => {
        let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const query = `update inquiryProducts set status = 0 where id = ${id}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("delete successful");
                toast.error("Product deleted successfully");
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };
    return (
        <div className="">
            <div className="form my-5">
                <div className="  inputes2 mt-2 mb-4 ">
                    <TextField
                        id="outlined-basic"
                        // value={34}
                        size="small"
                        label="New Product Name"
                        className="inputFieldproduct mt-3 px-2"
                        name="newProduct"
                        type="text"
                        variant="outlined"
                        onChange={(e) => {
                            setCurrentProduct(e.target.value);
                        }}
                    />

                    <TextField
                        id="outlined-basic"
                        // value={34}
                        size="small"
                        label="Unit Price"
                        className="inputField  px-2"
                        name="unitPrice"
                        type="number"
                        variant="outlined"
                        value={currentUnitPrice}
                        onChange={(e) => {
                            setCurrentUnitPrice(e.target.value);
                        }}
                    />

                    <Button
                        variant="contained"
                        className="AddButton mt-4"
                        onClick={insertNewProduct}
                    >
                        Add
                    </Button>
                </div>
            </div>
            <hr />
            <table>
                <tr>
                    <th>Product</th>

                    <th>Unit</th>
                    <th>Rate / sq.ft.</th>

                    <th>Action</th>
                </tr>

                {products &&
                    products.map((item, id) => {
                        return (
                            <tr key={id}>
                                <td>{item.label}</td>
                                <td>Sq.ft.</td>
                                <td>{item.price}</td>

                                <td>
                                    <span
                                        onClick={() => deleteProduct(item.id)}
                                    >
                                        <DeleteIcon color="error" />
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
            </table>
        </div>
    );
};

export default InquiryProduct;
