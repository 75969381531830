import React, { Component } from "react";
import { toast } from "react-toastify";
// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";

import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import logo from "../images/logo.png";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";

// picker
import TextField from "@mui/material/TextField";

//API handling components
import { API_URL } from "./../Global";

//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export class GSTNONGSTHistory extends Component {
    constructor(props) {
        super();

        this.state = {
            showUpdateModel: false,
            currentBillId: null,
            billList: null,
            itemsList: null,
            startingDate: "2021/08/25",
            endingDate: "2021/12/27",
        };
    }

    fetchGSTBillList = () => {
        let url = API_URL;
        const query = `SELECT *, 1 as type FROM gstBill as q where q.status=1 ORDER BY q.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    billList: [...this.state.billList, ...res.data],
                });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("Bill list fetch error: ", err);
            });
    };

    fetchNonGstBillList = () => {
        let url = API_URL;
        const query = `SELECT *, 2 as type FROM nonGstBill as q where q.status=1 ORDER BY q.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("bill: ", res.data);
                this.setState({ billList: res.data }, this.fetchGSTBillList);
            })
            .catch((err) => {
                console.log("Bill list fetch error: ", err);
            });
    };

    initializeDataTable() {
        $(function () {
            $("#billHistoryTable")
                .DataTable({
                    destroy: true,
                    dom:
                        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                        "<'row'<'col-sm-12' tr>>" +
                        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                    responsive: true,
                    lengthChange: true,
                    autoWidth: false,
                    buttons: ["csv", "print"],
                    order: [[0, "desc"]],
                })
                .buttons()
                .container();
        });
    }

    componentDidMount() {
        this.fetchNonGstBillList();
    }

    renderBillList = () => {
        if (this.state.billList == null) return null;

        // else
        return this.state.billList.map((bill) => {
            return (
                <tr align="center">
                    <td>{bill.id}</td>
                    <td>{["GST", "Non GST"][bill.type - 1]}</td>
                    {/* <td>{bill.companyType}</td> */}
                    {/* <td>{["imprint 24", "Sign Mart", " imprint 24 /Sign Mart"][bill.companyType - 1]}</td> */}
                    <td>
                        {bill.companyType == 1 ? "Imprint 24" : "Sign Mart"}
                    </td>
                    <td>{bill.partyName}</td>
                    <td>{bill.address}</td>
                    <td>{bill.mobileNo}</td>
                    <td>{bill.total}</td>
                    <td>{moment(bill.date).format("DD/MM/YYYY")}</td>
                </tr>
            );
        });
    };



    render() {
        console.log(this.state);
        return (
            <div className="my-4">
                <TextField
                    id="startingDate"
                    // label="startingDate"
                    variant="outlined"
                    format="MM/DD/YYY"
                    className="mr-2"
                    value={this.state.startingDate}
                    onChange={(e) =>
                        this.setState({ startingDate: e.target.value })
                    }
                    // required="true"
                    size="small"
                    type="date"
                    style={{ width: "180px" }}
                />
                {console.log("startingDate" + "" + this.state.startingDate)}

                <TextField
                    id="endingDate"
                    // label="endingDate"
                    format="MM/DD/YYY"
                    variant="outlined"
                    className="mr-2"
                    value={this.state.endingDate}
                    onChange={(e) =>
                        this.setState({ endingDate: e.target.value })
                    }
                    // required="true"
                    size="small"
                    type="date"
                    style={{ width: "180px" }}
                />
                {console.log("endingDate" + "" + this.state.endingDate)}

                <Button
                    color="primary"
                    variant="contained"
                    className="mt-1 mr-1 mb-3 ml-5"
                    onClick={this.refresh}
                >
                    <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                </Button>
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="billHistoryTable"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Bill Id</th>
                                        <th align="center">Bill Type</th>
                                        <th align="center">Company</th>
                                        <th align="center">Name</th>
                                        <th align="center">Address</th>
                                        <th align="center">Mobile No.</th>
                                        <th align="center">Total</th>
                                        <th align="center">Date</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderBillList()}</tbody>
                            </table>
                            <Modal
                                onHide={(e) =>
                                    this.setState({ showUpdateModel: false })
                                }
                                size="lg"
                                show={this.state.showUpdateModel}
                                // onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Bill Details</Modal.Title>
                                </Modal.Header>
                                <Row>
                                    {/* Overview */}

                                    <div
                                        className="ReceptContainerss"
                                        //   ref={(el) => setPrintComponentRef(el)}
                                        ref={(el) =>
                                            (this.printComponentRef = el)
                                        }
                                    >
                                        <div className="text-center">
                                            <div className="header  ReceptBg ">
                                                <div className="row logoContainer">
                                                    <div className="col-6 text-start">
                                                        <img
                                                            src={logo}
                                                            alt="logo"
                                                            className="img-fluid"
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <p className="py-3 pl-4 text-start">
                                                            Shop No.2, vithoba
                                                            Complex,
                                                            Sangli-Miraj Road{" "}
                                                            <br />{" "}
                                                            Nr.Parshwanthnagar
                                                            Bus Stop, Miraj -
                                                            416 410 (Maha){" "}
                                                            <br /> Mobile:
                                                            7293242424, e-mail:
                                                            imprint24.india@gmail.com
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="bg-secondary text-white px-2">
                                                INVOICE
                                            </h5>
                                            <div className="p-2 text-start">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p className="text-start">
                                                            Party Name: &nbsp;{" "}
                                                            {this.state.name}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {" "}
                                                            Phone No: &nbsp;
                                                            {
                                                                this.state
                                                                    .mobileNo
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Address: &nbsp;{" "}
                                                            {this.state.address}
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            Date :
                                                            <b>
                                                                {moment(
                                                                    new Date()
                                                                ).format(
                                                                    "D/M/YYYY"
                                                                )}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>
                                                            Payment Mode: &nbsp;{" "}
                                                            {
                                                                [
                                                                    "Cash",
                                                                    "Online",
                                                                ][
                                                                this.state
                                                                    .paymentMode -
                                                                1
                                                                ]
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className="bg-secondary text-secondary px-2">
                                                .
                                            </h6>

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>Sq. ft.</th>
                                                        <th>Unit</th>
                                                        <th>Rate / sq.ft.</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.itemsList &&
                                                        this.state.itemsList.map(
                                                            (item, id) => {
                                                                return (
                                                                    <tr
                                                                        key={id}
                                                                    >
                                                                        <td>
                                                                            {id +
                                                                                1}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.product
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.quantity
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.sqft
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            Sq.
                                                                            ft.
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.rate
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {Number(
                                                                                item.amount
                                                                            ).toFixed(
                                                                                2
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}

                                                    <tr>
                                                        <td colSpan={6}>
                                                            <b>Grand Total</b>
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {this.state
                                                                    .total &&
                                                                    this.state.total.toFixed(
                                                                        2
                                                                    )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                    <div className="Qrcode">
                                                        <img
                                                            src="/Assets/QrCode1.jpg"
                                                            className="qrimg"
                                                            alt="QrCode"
                                                        />
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* invoice */}
                                    <div className="">
                                        <ReactToPrint
                                            content={() =>
                                                this.printComponentRef
                                            }
                                        >
                                            <PrintContextConsumer>
                                                {({ handlePrint }) => (
                                                    <Button
                                                        onClick={handlePrint}
                                                        className="mb-5 mt-3 mr-3"
                                                        color="primary"
                                                        variant="contained"
                                                        style={{
                                                            float: "right",
                                                            marginRight: "40px",
                                                        }}
                                                    // disabled={saveFlag == true ? false : true}
                                                    // disabled={
                                                    //         (this.state.particular ||
                                                    //             this.state.quantity)
                                                    //             ? false
                                                    //             : true
                                                    //     }
                                                    >
                                                        PRINT
                                                    </Button>
                                                )}
                                            </PrintContextConsumer>
                                        </ReactToPrint>
                                    </div>
                                </Row>
                            </Modal>

                            {/* {this.renderUpdateModal()}
                            {this.renderDisplayBillModal()} */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default GSTNONGSTHistory;
