import React, { Component, useContext } from "react";
import { Badge, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import TextField from "@mui/material/TextField";

//API handling components
import { API_URL } from "../Global";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import { Autocomplete } from "@mui/material";
import FilterContext from "../App";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export class BasicRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            description: "",
            totalSqft: "",
            name: "",
            expenseData: {},
            activeExpenseId: null,
            selectedFile: null,
            activeDocPath: null,
            productListData: {},
            CurrentPrice: "",
            totalAmount: "",
            CurrentId: "",
            currentType: "",
            currentCompany: "",
            // currentType:this.props.currentType,
            // currentCompany:this.props.currentCompany,
        };
        console.log("currentCompany s" + this.props.currentCompany);
    }

    getExpenseData() {
        const query = `SELECT * from basicRates order by id desc;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log(res.data);
                this.setState(
                    { expenseData: res.data },
                    this.initializeDataTable
                );
                // this.initializeDataTable();
            })
            .catch((err) => {
                console.log(err);
                toast("failed to fetch");
            });
    }

    static contextType = FilterContext;

    // fetching data
    fetchProductList = () => {
        // let url = API_URL;
        // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
        const { currentType, currentCompany } = this.state;
        const query = `select id, name as label, price from products where status = 1 and customerType = 1 and company = 2 `;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                // console.log("pList" + this.state.productListData.map( (item) =>  item.price ))
                this.setState({
                    productListData: res.data,
                });
            })
            .catch((err) => {
                console.log("products fetch error: ", err);
            });
    };

    componentDidMount() {
        this.getExpenseData();
        this.fetchProductList();
    }
    componentDidUpdate() {
        this.initializeDataTable();
    }

    handleInputChange = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        });
    };

    submitFile() {
        const data = new FormData();
        data.append("fileToUpload", this.state.selectedFile);
        let url = "https://imprint24.5techg.com/fileUpload/index.php";

        axios.post(url, data).then((res) => {
            // then print response status
            this.setState({ activeDocPath: res.data }, this.handleSubmit);
        });
    }

    handleSubmit() {
        const {
            description,
            name,
            activeDocPath,
            CurrentId,
            CurrentPrice,
            totalSqft,
        } = this.state;
        const date = new Date();
        // const query = `INSERT INTO basicRates (description,date,name,amount, docPath,productCode,totalSqft,amountPerSqft) VALUES('${description}','${moment(
        //   date
        // ).format()}', '${name}', '${
        //   this.state.CurrentPrice * this.state.totalSqft
        // }', '${activeDocPath}', '${CurrentId}' , '${totalSqft}' ,  '${CurrentPrice}' , );`;
        const query = `INSERT INTO basicRates (description,date,name,amount, docPath,productCode,totalSqft,amountPerSqft) VALUES('${description}','${moment(
            date
        ).format()}','${name}','${
            this.state.CurrentPrice * this.state.totalSqft
        }','${activeDocPath}','${CurrentId}','${totalSqft}','${CurrentPrice}')`;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast("expense saved successfully");
                setTimeout(this.refresh, 3000);
            })
            .catch((err) => {
                console.log(err);
                toast("failed to save");
            });
    }

    deleteExpense(id) {
        const query = `DELETE from basicRates WHERE id=${id};`;
        console.log(query);
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("basicRates deleted successfully");
                this.refresh();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //   calc

    //  calculateTotalsqft(){
    //    let total =   this.state.CurrentPrice * this.state.amount

    //    this.setState({totalAmount : total })
    // //    return (
    // //    console.log(total)
    // //    )
    // console.log(this.state.totalAmount)
    //  }

    // code not usable
    calculateTotalsqft = () => {
        let total = this.state.CurrentPrice * this.state.amount;

        this.setState({ totalAmount: total });
    };
    // code not usable

    initializeDataTable() {
        $(function () {
            $("#expenses_table")
                .DataTable({
                    destroy: true,
                    dom:
                        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                        "<'row'<'col-sm-12' tr>>" +
                        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                    responsive: true,
                    lengthChange: true,
                    autoWidth: false,
                    buttons: ["csv", "print"],
                    order: [[0, "desc"]],
                })
                .buttons()
                .container();
        });
    }

    refresh() {
        window.location.reload(false);
    }

    render() {
        // console.log("currentType" + this.props.currentType)
        // console.log("currentCompany" + this.props.currentCompany)
        // this.setState({ currentType: this.props.currentType});
        // this.setState({ currentCompany: this.props.currentCompany});

        const currentCompanys = this.props.currentCompany;
        const currentTypes = this.props.currentType;

        //  console.log("ff" + currentCompanys)

        // if(currentCompanys != 0){
        //   this.setState({ currentCompany: this.props.currentCompany})
        // }else if(currentTypes != 0){
        //   this.setState({ currentCompany: this.props.currentTypes})

        // }else{
        //   console.log("no values in typs & compnay")
        // }

        return (
            <div className="container-fluid">
                <Card>
                    <Card.Body className="mt-0 pt-3">
                        <Card.Title>Manage Expenses</Card.Title>
                        <div>
                            <div className="mt-3 inputesC1 ">
                                <Autocomplete
                                    id="free-solo-demo"
                                    className="inputFieldp  mr-2    "
                                    options={
                                        this.state.productListData != null
                                            ? this.state.productListData
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // label="party name"
                                            label="Product"
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value != null) {
                                            console.log(value.id);
                                            // setCurrentProduct(value.label);
                                            this.setState({
                                                CurrentPrice: value.price,
                                            });
                                            this.setState({
                                                CurrentId: value.id,
                                            });
                                            console.log(
                                                "state" +
                                                    this.state.CurrentPrice
                                            );
                                        }
                                    }}
                                />

                                {/* <TextField
                  id="sqft"
                  size="small"
                  label="Unit"
                  className="inputField  px-2"
                  name="sqft"
                  variant="outlined"
                  // style={{ width: "150px" }}
                  value="Sq.ft."
                  readOnly
                /> */}

                                <TextField
                                    id="amount"
                                    label="Total Sqft"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    value={this.state.totalSqft}
                                    className="mr-3 inputField "
                                    // style={{ width: "100px" }}
                                    onChange={(e) =>
                                        this.setState({
                                            totalSqft: e.target.value,
                                        })
                                    }
                                />
                                {/* hiding amount */}
                                {/* <TextField
                  id="amount"
                  size="small"
                  label="Amount"
                  className="inputField  px-2"
                  name="amount"
                  variant="outlined"
                  // style={{ width: "150px" }}
                  value={this.state.CurrentPrice * this.state.totalSqft}
                  // onchange={(e) => this.setState({totalAmount: e.target.value})}
                  //                   onchange={ (e) =>
                  //                     // this.setState({ totalAmount : this.state.CurrentPrice * this.state.amount })

                  // // this.setState({totalAmount : total })
                  //                   }

                  onChange={(event, value) => {
                    if (value != null) {
                      this.setState({
                        totalAmount:
                          this.state.CurrentPrice * this.state.CurrentPrice,
                      });
                    }
                  }}
                  readOnly
                /> */}

                                <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    value={this.state.name}
                                    className="mr-3 inputField"
                                    // style={{ width: "100px" }}
                                    onChange={(e) =>
                                        this.setState({
                                            name: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.description}
                                    className="mr-3 inputField"
                                    // style={{ minWidth: "30vw" }}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }
                                />

                                <TextField
                                    name="fileToUpload"
                                    size="small"
                                    type="file"
                                    variant="outlined"
                                    className="mr-3 inputField"
                                    onChange={this.handleInputChange}
                                />
                                <TextField
                                    id="unitPrice"
                                    label="Amount per sq.ft."
                                    variant="outlined"
                                    size="small"
                                    className="inputField  px-2"
                                    sx={{ width: 150 }}
                                    // md={{ width: 100 }}

                                    // style={{ width: "130px" }}
                                    value={this.state.CurrentPrice}
                                    onChange={(e) =>
                                        this.setState({
                                            CurrentPrice: e.target.value,
                                        })
                                    }
                                />

                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => this.submitFile()}
                                >
                                    Add expense
                                </button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div
                    // component={''}
                    style={{ width: "100%" }}
                    className="mt-2"
                >
                    <table
                        id="expenses_table"
                        className="display"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th align="center">Expense Id</th>
                                <th>Name</th>
                                <th>Product code</th>
                                <th>Total Sqft</th>
                                <th> Amount Per Sqft</th>
                                <th>Amount</th>
                                <th>Description</th>

                                <th>Date</th>
                                <th align="center">Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.expenseData.length > 0 ? (
                                this.state.expenseData.map((expense) => {
                                    return (
                                        <tr key={expense.id}>
                                            <td align="center">
                                                <Badge variant="primary">
                                                    {" "}
                                                    {expense.id}
                                                </Badge>
                                            </td>
                                            <td> {expense.name}</td>
                                            <td> {expense.productCode}</td>
                                            <td> {expense.totalSqft}</td>
                                            <td> {expense.amountPerSqft}</td>
                                            <td> {expense.amount}</td>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {expense.description}
                                            </td>

                                            <td>
                                                {moment(expense.date).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </td>
                                            <td align="center">
                                                {expense.docPath !=
                                                    "File upload failed" && (
                                                    <a
                                                        href={`${expense.docPath}`}
                                                        download
                                                        target="_blank"
                                                        className="disabled"
                                                    >
                                                        <PreviewIcon color="success" />
                                                    </a>
                                                )}
                                                <DeleteIcon
                                                    className="CPointer"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            activeExpenseId:
                                                                expense.id,
                                                        });
                                                        this.setState({
                                                            showDeleteModal: true,
                                                        });
                                                    }}
                                                    color="error"
                                                />
                                                <Modal
                                                    show={
                                                        this.state
                                                            .showDeleteModal
                                                    }
                                                    onHide={(e) =>
                                                        this.setState({
                                                            showDeleteModal: false,
                                                        })
                                                    }
                                                    size="md"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">
                                                            Delete expense
                                                            record
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                            Do you really want
                                                            to delete this
                                                            expense?
                                                        </p>
                                                        <button
                                                            color="danger"
                                                            variant="contained"
                                                            className="mt-1 mb-1"
                                                            onClick={() => {
                                                                this.deleteExpense(
                                                                    this.state
                                                                        .activeExpenseId
                                                                );
                                                                this.setState({
                                                                    showDeleteModal: false,
                                                                });
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </Modal.Body>
                                                </Modal>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td>No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={3000}
                />
            </div>
        );
    }
}

export default BasicRates;
