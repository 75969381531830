import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { API_URL } from '../../Global';
import { CurrencyRupee, Refresh } from '@mui/icons-material';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: 'name', label: 'Product Name', minWidth: 170 },
    { id: 'quantity', label: 'Quantity', minWidth: 100 },
    { id: 'width', label: 'Width', minWidth: 100 },
    { id: 'height', label: 'Height', minWidth: 100 },
    { id: 'area', label: 'Area(feet)^2', minWidth: 100 },
    { id: 'base_price', label: 'Base Price', minWidth: 100 },
    { id: 'unit_price', label: 'Sell Price', minWidth: 100 },
    { id: 'profit', label: 'Profit', minWidth: 100 },
    { id: 'loss', label: 'Loss', minWidth: 100 },
    { id: 'gst', label: 'GST(Rs)', minWidth: 100 },
    { id: 'total_price', label: 'Total Price', minWidth: 100 },
    // { id: 'code', label: 'Area', minWidth: 100 },
    // {
    //     id: 'population',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-IN'),
    // },
    // {
    //     id: 'size',
    //     label: 'Size\u00a0(km\u00b2)',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    // {
    //     id: 'density',
    //     label: 'Density',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toFixed(2),
    // },
];

function createData(name, quantity, width, height, area, base_price, unit_price, gst, total_price) {
    // const density = population / size;
    let profit = 0
    let loss = 0
    if (base_price < (unit_price / quantity)) {
        profit = (((unit_price / quantity) - base_price) * quantity) * (width * height)
    } else {
        loss = ((base_price - (unit_price / quantity)) * quantity) * (width * height)
    }
    return { name, quantity, width, height, area, base_price, unit_price, profit, loss, gst, total_price };
}


function subtotal(items, type) {
    let value = 0
    switch (type) {
        case 'gst':
            value = items.map(({ gst }) => gst).reduce((sum, i) => sum + i, 0);
            break;
        case 'total':
            value = items.map(({ total_price }) => total_price).reduce((sum, i) => sum + i, 0);
            break;
        case 'profit':
            value = items.map(({ profit }) => profit).reduce((sum, i) => sum + i, 0);
            break;
        case 'loss':
            value = items.map(({ loss }) => loss).reduce((sum, i) => sum + i, 0);
            break;
        default:
            break;
    }
    return value
}


// function subtotal(items, type) {
//     let value = 0
//     console.log({items, type});
//     switch (type) {
//         case 'gst':
//             value = items.map((row) => {
//                 if (row.billType === 1) {
//                     return row.gst
//                 } else {
//                     return 0
//                 }
//             }).reduce((sum, i) => sum + i, 0);
//             break;
//         case 'total':
//             value = items.map(({ total_price }) => total_price).reduce((sum, i) => sum + i, 0);
//             break;
//         case 'profit':
//             value = items.map(({ rate, price }) => {
//                 if (price < rate) {
//                     return (rate - price)
//                 } else {
//                     return 0
//                 }
//             }).reduce((sum, i) => sum + i, 0);
//             break;
//         case 'loss':
//             value = items.map(({ rate, price }) => {
//                 if (price > rate) {
//                     return (price - rate)
//                 } else {
//                     return 0
//                 }
//             }).reduce((sum, i) => sum + i, 0);
//             break;
//         default:
//             break;
//     }
// console.log(value);
//     return value
// }


function ProductListTable({ tableList }) {
    const rows = tableList.map((list) => {
        return createData(list.name, list.quantity, list.width, list.height, list.sqft, list.price, list.rate, list.gst, list.total)
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const totalProfit = subtotal(rows, 'profit')
    const totalLoss = subtotal(rows, 'loss')
    const totalGst = subtotal(rows, 'gst')
    const total = subtotal(rows, 'total')

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        <TableRow>
                            <TableCell colSpan={5}></TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Total</TableCell>
                            <TableCell colSpan={1}></TableCell>
                            <TableCell>{totalProfit}</TableCell>
                            <TableCell>{totalLoss}</TableCell>
                            <TableCell>{totalGst}</TableCell>
                            <TableCell>{total}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}



// ##################----CALCULATE EXPENSES OF CURRENT COMPANY FROM SERVER----###################----START----########################
async function fetchExpensesAmount({ queryUrl }) {
    let url = API_URL;
    const query = queryUrl;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    const response = await axios.post(url, data)
    if (response.status === 200) {
        if (response.data[0].total_amount === null) {
            return 0
        } else {
            return response.data[0].total_amount
        }
    } else {
        return 0
    }
}
// ##################----CALCULATE EXPENSES OF CURRENT COMPANY FROM SERVER----###################----END----########################


// ##################----FETCHING PRODUCTS OF CURRENT COMPANY FROM SERVER----###################----START----########################
async function fetchProducts({ queryUrl }) {
    let url = API_URL;
    const query = queryUrl;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    const response = await axios.post(url, data)
    if (response.status === 200) {
        return response.data
    } else {
        return []
    }
}
// ##################----FETCHING PRODUCTS OF CURRENT COMPANY FROM SERVER----###################----END----########################

// ##################----FETCHING PRODUCTS OF CURRENT COMPANY FROM SERVER----###################----START----########################
async function fetchProductWithGSTandBillList({ queryUrl }) {
    let url = API_URL;
    const query = queryUrl;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    const response = await axios.post(url, data)
    if (response.status === 200) {
        return response.data
    } else {
        return []
    }
}
// ##################----FETCHING PRODUCTS OF CURRENT COMPANY FROM SERVER----###################----END----########################



function MediaCard(props) {
    return (
        <Card sx={{ padding: 0, background: props.background_color }}>
            <CardContent sx={{ p: 2 }}>
                <Typography gutterBottom variant="h6" component="div" sx={{ textAlign: 'start', color: 'white' }}>
                    {props.title}
                </Typography>
                <Typography sx={{ textAlign: 'start', color: 'white', fontWeight: 500 }} variant="h4">
                    <CurrencyRupee /> {props.value}
                </Typography>
            </CardContent>
        </Card>
    );
}


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.primary
}));



const initialValue = {
    name: '',
    price: 0,
    base_price: 0,
    id: ""
}


const Dashboard = ({ currentType, currentCompany }) => {
    // console.log(currentType, currentCompany);
    const [expenseAmount, setExpenseAmount] = useState(0)
    const [expenseAmountMonthly, setExpenseAmountMonthly] = useState(0)
    const [expenseAmountToday, setExpenseAmountToday] = useState(0)
    const [nonGSTBillAmount, setNonGSTBillAmount] = useState(0)
    const [nonGSTBillAmountMonthly, setNonGSTBillAmountMonthly] = useState(0)
    const [nonGSTBillAmountToday, setNonGSTBillAmountToday] = useState(0)
    const [GSTBillAmount, setGSTBillAmount] = useState(0)
    const [GSTBillAmountMonthly, setGSTBillAmountMonthly] = useState(0)
    const [GSTBillAmountToday, setGSTBillAmountToday] = useState(0)
    const [tableList, setTableList] = useState([])
    // const [products, setProducts] = useState([])
    const [productList, setProductList] = useState([])
    const [userSelect, setUserSelect] = useState(initialValue)

    useEffect(async () => {
        setUserSelect(initialValue)
        //  #####################----FETCHING DASHBOARD EXPENSES----######################----START----##################################
        setExpenseAmount(await fetchExpensesAmount({ queryUrl: `SELECT SUM(amount) as total_amount FROM expenses;` }))
        setNonGSTBillAmount(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) as total_amount FROM nonGstBill WHERE companyType = ${currentCompany};` }))
        setGSTBillAmount(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) as total_amount FROM gstBill WHERE companyType = ${currentCompany};` }))
        //  #####################----FETCHING DASHBOARD EXPENSES----######################----END----##################################


        //  #####################----FETCHING MONTHLY EXPENSES----######################----START----##################################
        setExpenseAmountMonthly(await fetchExpensesAmount({ queryUrl: `SELECT SUM(amount) AS total_amount FROM expenses WHERE MONTH(last_modified) = MONTH(CURDATE()) AND YEAR(last_modified) = YEAR(CURDATE());` }))
        setGSTBillAmountMonthly(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) AS total_amount FROM gstBill WHERE MONTH(last_modified) = MONTH(CURDATE()) AND YEAR(last_modified) = YEAR(CURDATE()) AND companyType = ${currentCompany};` }))
        setNonGSTBillAmountMonthly(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) AS total_amount FROM nonGstBill WHERE MONTH(last_modified) = MONTH(CURDATE()) AND YEAR(last_modified) = YEAR(CURDATE()) AND companyType = ${currentCompany};` }))
        //  #####################----FETCHING MONTHLY EXPENSES----######################----END----##################################


        //  #####################----FETCHING tODAY EXPENSES----######################----START----##################################
        setNonGSTBillAmountToday(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) AS total_amount FROM nonGstBill WHERE last_modified >= CURDATE() AND last_modified < CURDATE() + INTERVAL 1 DAY AND companyType = ${currentCompany};` }))
        setExpenseAmountToday(await fetchExpensesAmount({ queryUrl: `SELECT SUM(amount) AS total_amount FROM expenses WHERE last_modified >= CURDATE() AND last_modified < CURDATE() + INTERVAL 1 DAY;` }))
        setGSTBillAmountToday(await fetchExpensesAmount({ queryUrl: `SELECT SUM(total) AS total_amount FROM gstBill WHERE last_modified >= CURDATE() AND last_modified < CURDATE() + INTERVAL 1 DAY AND companyType = ${currentCompany};` }))
        //  #####################----FETCHING tODAY EXPENSES----######################----END----##################################


        //  #####################----FETCHING CURRENT COMPANY PRODUCTS----######################----START----##################################
        setProductList(await fetchProducts({ queryUrl: `SELECT id, name, price, base_price FROM products WHERE status = 1 AND customerType = ${currentType} AND company = ${currentCompany}` }))
        //  #####################----FETCHING CURRENT COMPANY PRODUCTS----######################----END----##################################
    }, [currentType, currentCompany])


    useEffect(async () => {
        if (userSelect) {
            setTableList(await fetchProductWithGSTandBillList({
                queryUrl: `SELECT billList.*, billList.id AS ids, gstBill.*, products.* FROM billList JOIN products ON billList.product_id = products.id JOIN gstBill ON billList.billId = gstBill.id WHERE billList.product_id = '${userSelect.id}'`
            }));
        }
    }, [userSelect])

    return (
        <>
            {/* #####################----DASHBOARD EXPENSES CONTAINER----####################----START----############################### */}
            <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Typography variant='h4' sx={{ fontSize: '26px' }}>Dashboard</Typography>
                {/* #####################----DASHBOARD EXPENSES GRID BOX----######################----START----############################### */}
                <Grid container>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item>{MediaCard({ background_color: '#17a2b8', value: expenseAmount, title: 'Total Expense' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item>{MediaCard({ background_color: '#28a745', value: nonGSTBillAmount, title: 'Non GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item>{MediaCard({ background_color: '#dc3545', value: GSTBillAmount, title: 'GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item>{MediaCard({ background_color: '#ffc107', value: expenseAmount, title: 'Total Expense' })}</Item>
                    </Grid>
                </Grid>
                {/* #####################----DASHBOARD EXPENSES GRID BOX----######################----END----################################## */}
            </Box>
            {/* #####################----DASHBOARD EXPENSES CONTAINER----####################----END----############################### */}



            {/* #####################----TODAY EXPENSES CONTAINER----####################----START----############################### */}
            <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Typography variant='h4' sx={{ fontSize: '26px' }}>Today</Typography>
                {/* #####################----TODAY EXPENSES GRID BOX----######################----START----################################## */}
                <Grid container>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#dc3545', value: expenseAmountToday, title: 'Total Expense' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#17a2b8', value: nonGSTBillAmountToday, title: 'Non GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#ffc107', value: GSTBillAmountToday, title: 'GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#28a745', value: expenseAmount, title: 'Total Expense' })}</Item>
                    </Grid>
                </Grid>
                {/* #####################----TODAY EXPENSES GRID BOX----######################----END----################################## */}
            </Box>
            {/* #####################----TODAY EXPENSES CONTAINER----####################----END----############################### */}


            {/* #####################----MONTHLY EXPENSES CONTAINER----####################----START----############################### */}
            <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Typography variant='h4' sx={{ fontSize: '26px' }}>Monthly</Typography>
                {/* #####################----MONTHLY EXPENSES GRID BOX----######################----START----################################## */}
                <Grid container>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#ffc107', value: expenseAmountMonthly, title: 'Total Expense' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#28a745', value: nonGSTBillAmountMonthly, title: 'Non GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#17a2b8', value: GSTBillAmountMonthly, title: 'GST Bill Amount' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#dc3545', value: expenseAmount, title: 'Total Expense' })}</Item>
                    </Grid>
                    
                </Grid>
                {/* #####################----MONTHLY EXPENSES GRID BOX----######################----END----################################## */}
            </Box>
            {/* #####################----MONTHLY EXPENSES CONTAINER----####################----END----############################### */}



            {/* #####################----PRODUCT CONTAINER----######################----START----################################## */}
            <Box sx={{ flexGrow: 1, mt: 2 }}>
                {/* #####################----PRODUCT SEARCH TEXTFIELD----######################----START----################################## */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h4' sx={{ fontSize: '26px' }}>Product</Typography>
                    <Box sx={{ width: '35%', display: 'flex', justifyContent: 'space-between' }}>
                        <Autocomplete
                            fullWidth
                            id="free-solo-demo"
                            size='small'
                            options={productList}
                            value={userSelect}
                            getOptionLabel={value => `${value.name}`}
                            noOptionsText="Product not found"
                            onChange={(event, newValue) => setUserSelect(newValue)}
                            renderInput={(params) => <TextField size='small' placeholder='Select a product' sx={{ width: '100%' }} {...params} label='Select Product' />}
                        />
                        <Button onClick={async () => {
                            setProductList(await fetchProducts({ queryUrl: `SELECT id, name, price, base_price FROM products WHERE status = 1 AND customerType = ${currentType} AND company = ${currentCompany}` }))
                        }}><Refresh /></Button>
                    </Box>
                </Box>
                {/* #####################----PRODUCT SEARCH TEXTFIELD----######################----END----################################## */}


                {/* #####################----PRODUCT GRID BOX----######################----START----################################## */}
                {/* <Grid container>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#17a2b8', value: (userSelect ? (userSelect.price < userSelect.base_price ? 0 : userSelect.price - userSelect.base_price) : 0), title: 'Profits' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#dc3545', value: (userSelect ? (userSelect.price < userSelect.base_price ? userSelect.base_price - userSelect.price : 0) : 0), title: 'Loss' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#ffc107', value: userSelect ? userSelect.base_price : 0, title: 'Base Price' })}</Item>
                    </Grid>
                    <Grid xs={3} sx={{ p: 1 }}>
                        <Item sx={{ padding: 0 }}>{MediaCard({ background_color: '#28a745', value: userSelect ? userSelect.price : 0, title: 'Unit Price' })}</Item>
                    </Grid>
                </Grid> */}
                <Grid container>
                    <ProductListTable tableList={tableList} />
                </Grid>

                {/* #####################----PRODUCT GRID BOX----######################----END----################################## */}
            </Box>
            {/* #####################----PRODUCT CONTAINER----######################----END----################################## */}
        </>
    )
}

export default Dashboard