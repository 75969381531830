import React, { Component } from "react";
import { toast } from "react-toastify";
// datatable
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";

import { Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import logo from "../../images/logo.png";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Button from "@mui/material/Button";

//API handling components
import { API_URL } from "../../Global";

// datatable
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

const axios = require("axios");

export class Analytics extends Component {
    constructor(props) {
        super();

        this.state = {
            showUpdateModel: false,
            currentAnalyticsId: null,
            analyticsList: null,
            itemsList: null,
            printComponentRef: null,
        };
    }

    fetchAnalyticsList = () => {
        let url = API_URL;
        const query = `SELECT DISTINCT b.*,  b.particular as name, CASE when b.billType = 1 then g.date when b.billType = 2 then ng.date end as dates,
        case when p.company = 1 then 'imprint 24' else 'signmart' end as company
        
        from billList as b left join gstBill g on b.billId = g.id and b.billType=1 left join nonGstBill ng on b.billId = ng.id and b.billType=2 
        left join products p on b.particular = p.name
        
        where b.status=1 and b.billType in (1,2)`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("analytics: ", res.data);
                this.setState({ analyticsList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("analytics list fetch error: ", err);
            });
    };

    initializeDataTable() {
        $("#analyticsTable")
            .DataTable({
                destroy: true,
                dom:
                    "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                    "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                    "<'row'<'col-sm-12' tr>>" +
                    "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
                responsive: true,
                lengthChange: true,
                autoWidth: false,
                buttons: ["csv", "print"],
                order: [[0, "desc"]],
            })
            .buttons()
            .container()
            .appendTo("#sitesTable_wrapper .col-md-6:eq(0)");
    }

    componentDidMount() {
        this.fetchAnalyticsList();
    }

    renderAnalyticsList = () => {
        if (this.state.analyticsList == null) return null;

        // else
        return this.state.analyticsList.map((analytics) => {
            return (
                <tr align="center">
                    <td>{analytics.id}</td>
                    <td>{analytics.company}</td>
                    <td>{analytics.name}</td>
                    <td>{analytics.sqft}</td>
                    <td>{analytics.billId}</td>
                    <td>{moment(analytics.dates).format("DD/MM/YYYY")}</td>
                    <td>{analytics.amount}</td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="">
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="analyticsTable"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Ids</th>
                                        <th align="center">Compamy</th>
                                        <th align="center">Prod. Name</th>
                                        <th align="center">Sq. ft.</th>
                                        <th align="center">Bill ID</th>
                                        <th align="center">Date</th>
                                        <th align="center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderAnalyticsList()}</tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Analytics;
